import { Page, pickSeoProps } from "@components/page/Page"
import { Blocks } from "@components/ui/blocks"
import { Footer } from "@generated/models/Footer"
import { Homepage as HomepageModel } from "@generated/models/Homepage"
import { Navigation } from "@generated/models/Navigation"
import { fetchContent, SingleItemResponse } from "@lib/kontent"
import { GetStaticProps, NextPage } from "next"
import React from "react"

type HomepageProps = {
  navigation: SingleItemResponse<Navigation>
  footer: SingleItemResponse<Footer>
  content: HomepageModel
  modular_content: { [key: string]: any }
}

const Homepage: NextPage<HomepageProps> = ({
  content,
  navigation,
  footer,
  modular_content,
}) => {
  return (
    <Page navigation={navigation} footer={footer} seo={pickSeoProps(content)}>
      <Blocks content={content} modular_content={modular_content} />
    </Page>
  )
}

export const getStaticProps: GetStaticProps = async ({ preview, params }) => {
  const navigation = await fetchContent<SingleItemResponse<Navigation>>({
    path: "/navigation",
    preview,
  })

  const footer = await fetchContent<SingleItemResponse<Footer>>({
    path: "/footer",
    preview,
  })

  const content = await fetchContent<SingleItemResponse<HomepageModel>>({
    path: "/homepage",
    params: {
      depth: 3,
    },
    preview,
  })

  return {
    props: {
      navigation,
      footer,
      content: content.item.elements,
      modular_content: content.modular_content,
    },
  }
}

export default Homepage
